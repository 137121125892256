import React, { useState, useRef } from 'react';
import { useParams } from 'react-router';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Grid,
  Paper,
  Menu,
  MenuItem,
  Button,
  Box,
  FormControl,
  Typography,
  TextField,
  Stack,
  Modal,
  AppBar
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Toolbar from '@mui/material/Toolbar';
import InputLabel from '@mui/material/InputLabel';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreIcon from '@mui/icons-material/MoreVert';
import { AppSMService } from '../../../network/useAxios';
import Breadcrumb from '../../organisms/Breadcrumb/Breadcrumb';
import TabHeader from '../../organisms/TabHeader/TabHeader';
import Alert from '../../molecules/Alert/Alert';
import Dialog from '../../molecules/Dialog/Dialog';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useStudyAccessRights } from '../../../utils/useStudyAccessRights';
import { BREAD_CRUMBS, ROUTING_CONSTANTS, TEXT_CONSTANTS, useDataValidation } from '../../../utils';
import { useAppSelector } from '../../../hooks';
import { InitialStateTypes } from '../../../store/slices/globalSlice';
import { STORE_CONFIG } from '../../../store/storeConstant';
import { META_DATA_TYPE, MetaDataState, TabHValues } from './types';
import { HTTP_STATUS_CODE, NETWORK_CONSTANTS } from '../../../network/networkConstants';

const tabHeader = [TabHValues.VISIT, TabHValues.SUBJECT, TabHValues.SITE];
const Metadata = () => {
  const { HOME, STUDY_MANAGEMENT, META_DATA: BMETA_DATA } = BREAD_CRUMBS;
  const { DASHBOARD, STUDY: RSTUDY } = ROUTING_CONSTANTS;

  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { study, client, user } = appDataContext;
  const { checkIsEmpty } = useDataValidation();
  const breadCrumbList = [
    { title: HOME, link: DASHBOARD },
    { title: STUDY_MANAGEMENT, link: `${RSTUDY}${client.id}/${study.id}` },
    { title: BMETA_DATA, link: '' }
  ];
  const {
    VALIDATION_TEXT: {
      COMMON: { ERROR, SUCCESS, FIELD_REQUIRED }
    }
  } = TEXT_CONSTANTS;
  const { META_DATA, STUDY } = NETWORK_CONSTANTS.END_POINTS;
  const defaultFieldValue = {
    name: '',
    errorname: false,
    label: '',
    errorlabel: false,
    type: '',
    errortype: false,
    newconfig: true
  };
  const [metaDataState, setMetaDataState] = useState<MetaDataState>({
    metaDataList: [],
    metaDataMainList: [],
    activeTab: 0,
    configID: '',
    open: false,
    fieldName: [],
    fieldsTempName: [],
    fieldList: [],
    fieldValue: [],
    randomNumber: 0,
    randUpdate: 0,
    btnloading: false,
    openAlert: false,
    openAlertMsg: '',
    openAlertStatus: '',
    type: '',
    openDialog: false,
    gridLoading: false,
    anchorEl: null,
    openModle: false,
    openDataModle: false
  });
  const {
    activeTab,
    configID,
    fieldList,
    fieldName,
    fieldValue,
    metaDataList,
    metaDataMainList,
    open,
    openAlert,
    openAlertMsg,
    openAlertStatus,
    openDialog,
    randUpdate,
    anchorEl,
    openDataModle,
    openModle
  } = metaDataState;
  const editID = useRef(0);
  const { studyID } = useParams();

  const { createSiteMetaDataAccessRights, createSubjectMetaDataAccessRights } =
    useStudyAccessRights();
  React.useEffect(() => {
    loadMetadata(META_DATA_TYPE.VISIT);
  }, []);
  React.useEffect(() => {
    loadDatas(metaDataMainList, 'Yes');
  }, [randUpdate]);
  React.useEffect(() => {
    loadStudyConfigData();
  }, [fieldName]);
  const updateState = (data: any) => {
    setMetaDataState((prevState: MetaDataState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };
  const loadMetadata = (metaType: string) => {
    AppSMService.axios
      .get(`${META_DATA}?type=${metaType}&studyId=${studyID}`)
      .then(({ status, data }) => {
        if (status === HTTP_STATUS_CODE.OK) {
          updateState({ metaDataMainList: data });
          loadDatas(data, 'Yes');
        }
      });
  };

  const loadDatas = (fielddata: any, type: string) => {
    const existFieldList: any = [];
    const field_Name: any = [];
    if (fielddata.length > 0) {
      if (fielddata[0].data.length > 0) {
        fielddata[0].data.map((item: any) => {
          field_Name.push({
            field: item.name,
            headerName: item.label,
            width: 200,
            editable: true
          });
          existFieldList.push({
            name: item.name,
            errorname: false,
            label: item.label,
            errorlabel: false,
            type: item.type,
            errortype: false,
            value: '',
            errorvalue: false,
            newconfig: false
          });
        });
      }
      const _showActionColumn =
        activeTab === 0
          ? true
          : activeTab === 1
            ? createSubjectMetaDataAccessRights
            : createSiteMetaDataAccessRights;

      field_Name.push({
        field: 'action',
        headerName: 'Action',
        width: 250,
        renderCell: (params: GridRenderCellParams): React.ReactNode => {
          return (
            <>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(event) => handleClick(event, params)}>
                <MoreIcon />
              </Button>
              {_showActionColumn && (
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}>
                  <MenuItem onClick={handleEditMeta}>Edit</MenuItem>
                  <MenuItem onClick={handleRemove}>Remove</MenuItem>
                </Menu>
              )}
            </>
          );
        }
      });

      if (type === 'Yes') {
        //   loadStudyConfigData();
      }
    }
    updateState({
      fieldName: field_Name,
      fieldValue: existFieldList,
      configID: fielddata.length > 0 ? fielddata[0].id : ''
    });
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    params: GridRenderCellParams
  ) => {
    editID.current = params.row.id;
    updateState({ anchorEl: event.currentTarget, open: !open, randUpdate: Math.random() });
  };
  const handleClose = () => {
    editID.current = 0;
    updateState({ anchorEl: null, open: !open, randUpdate: Math.random() });
  };

  const handleEditMeta = () => {
    AppSMService.axios
      .get(`${STUDY}/${studyID}/config/${editID.current}`)
      .then(({ status, data }) => {
        const updFieldList: any = [];
        if (status === HTTP_STATUS_CODE.OK) {
          const metaResult = data.data;
          fieldValue.map((item: any) => {
            const metadata_List = item;
            metadata_List['value'] = !checkIsEmpty(metaResult[item.name])
              ? metaResult[item.name]
              : '';
            updFieldList.push(metadata_List);
          });
        }
        updateState({ fieldValue: updFieldList });
        handleopenDataPopup();
      });
  };
  const handleRemove = () => {
    updateState({ anchorEl: null, openDialog: true });
  };

  const loadStudyConfigData = () => {
    const metaType = getMetaType(activeTab);
    if (metaType === META_DATA_TYPE.SUBJECT) {
      updateState({ metaDataList: [] });
    } else {
      AppSMService.axios
        .get(`${STUDY}/${studyID}/config?type=${metaType}`)
        .then(({ status, data }) => {
          const updatedMetaData: any = [];
          if (status === HTTP_STATUS_CODE.OK) {
            if (data.length > 0) {
              data.map((item: any) => {
                const metadataList = item.data;
                metadataList['id'] = item.id;
                updatedMetaData.push(metadataList);
              });
            }
          }
          updateState({ metaDataList: updatedMetaData });
        });
    }
  };

  const handleopenPopup = () => {
    const updatefieldList = fieldValue;
    let allowtoAddNew = false;
    if (updatefieldList.length > 0) {
      if (updatefieldList[updatefieldList.length - 1].name !== '') {
        allowtoAddNew = true;
      }
    } else {
      allowtoAddNew = true;
    }
    if (allowtoAddNew) {
      updatefieldList.push(defaultFieldValue);
    }
    updateState({ fieldList: updatefieldList, openModle: !openModle });
  };
  const handlePopupClose = () => {
    updateState({ openModle: false, openDataModle: false });
  };
  const handleopenDataPopup = () => {
    updateState({ openDataModle: true });
  };
  const handleopenDataNewPopup = () => {
    editID.current = 0;
    updateState({ openDataModle: true });
  };

  const columns: GridColDef[] = fieldName;
  const handleSetActiveTab = (value: number) => {
    updateState({ activeTab: value, configID: '' });
    loadMetadata(getMetaType(value));
  };
  const getMetaType = (key: number) => {
    switch (key) {
      case 1:
        return META_DATA_TYPE.SUBJECT;
      case 2:
        return META_DATA_TYPE.SITE;

      default:
        return META_DATA_TYPE.VISIT;
    }
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    updIndex: number
  ) => {
    let value = event.target.value;
    const name = event.target.name;
    if (name === 'name') {
      value = value
        .replaceAll(' ', '')
        .replace(/[^a-zA-Z0-9_ ]/g, '')
        .toLowerCase();
    }
    updateConfig(name, value, updIndex);
  };
  const handleSelectFieldChange = (event: SelectChangeEvent<string>, updIndex: number) => {
    const value = event.target.value;
    const name = event.target.name;
    updateConfig(name, value, updIndex);
  };

  const updateConfig = (name: string, updatevalue: string, updIndex: number) => {
    const updatefieldList: any = [];
    fieldList.map((item, index) => {
      if (updIndex === index) {
        const columnname = name === 'name' ? updatevalue : item.name;
        const label = name === 'label' ? updatevalue : item.label;
        const type = name === 'type' ? updatevalue : item.type;
        const errorname =
          name === 'name' && columnname === ''
            ? true
            : name === 'name' && columnname !== ''
              ? false
              : item.errorname;
        const errorlabel =
          name === 'label' && label === ''
            ? true
            : name === 'label' && label !== ''
              ? false
              : item.errorlabel;

        const errortype =
          name === 'type' && type === ''
            ? true
            : name === 'type' && type !== ''
              ? false
              : item.errortype;
        updatefieldList.push({
          name: columnname,
          errorname: errorname,
          label: label,
          errorlabel: errorlabel,
          type: type,
          errortype: errortype,
          newconfig: item.newconfig
        });
      } else {
        updatefieldList.push(item);
      }
    });
    updateState({ fieldList: updatefieldList });
  };
  const addNewConfig = () => {
    const updatefieldList = fieldList;

    updatefieldList.push({
      name: '',
      errorname: false,
      label: '',
      errorlabel: false,
      type: '',
      errortype: false,
      newconfig: true
    });
    updateState({ fieldList: updatefieldList, randomNumber: Math.random() });
  };
  const handleRemoveConfig = (removeIndex: number) => {
    const update_field_List: any = [];
    fieldList.map((item: any, index: number) => {
      if (removeIndex !== index) {
        update_field_List.push(item);
      }
    });
    updateState({ fieldList: update_field_List, randomNumber: Math.random() });
  };
  const addConfigField = () => {
    addMetaConfig();
  };

  const addMetaConfig = () => {
    let error = 0;
    const finalfieldList: any = [];
    const sendConfigField: any = [];
    if (fieldList.length > 0) {
      fieldList.map((item: any) => {
        item['errorname'] = item.name === '' ? true : false;
        item['errorlabel'] = item.label === '' ? true : false;
        item['errortype'] = item.type === '' ? true : false;
        if (item.errorname === true || item.errorlabel === true || item.errortype === true) {
          error++;
        } else {
          sendConfigField.push({
            name: item.name,
            label: item.label,
            type: item.type
          });
        }

        finalfieldList.push(item);
      });
      if (error === 0) {
        const metaType = getMetaType(activeTab);

        if (configID !== '') {
          AppSMService.axios
            .patch(`${META_DATA}/${configID}`, {
              type: metaType,
              studyId: studyID,
              data: sendConfigField
            })
            .then(() => {
              handlePopupClose();
              loadMetadata(metaType);
            })
            .catch((e) => {
              updateState({
                openAlertStatus: ERROR,
                openAlertMsg: e?.response?.data,
                openDialog: false,
                openAlert: true
              });
            });
        } else {
          AppSMService.axios
            .post(`metadata/`, {
              type: metaType,
              studyId: studyID,
              data: sendConfigField
            })
            .then(() => {
              handlePopupClose();
              loadMetadata(metaType);
            })
            .catch((e) => {
              updateState({
                openAlertStatus: ERROR,
                openAlertMsg: e?.response?.data,
                openDialog: false,
                openAlert: true
              });
            });
        }
      } else {
        updateState({ randomNumber: Math.random() });
      }
    }
  };

  const handleTextValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    updIndex: number
  ) => {
    const value = event.target.value;
    updateValue(value, updIndex);
  };
  const updateValue = (value: any, updIndex: number) => {
    const updfieldValue: any = [];
    fieldValue.map((item: any, index: number) => {
      if (index === updIndex) {
        item['value'] = value;
        item['errorvalue'] = value === '' ? true : false;
      }
      updfieldValue.push(item);
    });
    updateState({ fieldValue: updfieldValue });
  };

  const addValue = () => {
    let sendfieldValue = '{';
    fieldValue.map((item: any, index: number) => {
      sendfieldValue += '"' + item.name + '":"' + item.value + '"';
      if (index !== fieldValue.length - 1) {
        sendfieldValue += ',';
      }
    });
    sendfieldValue += '}';
    const metaType = getMetaType(activeTab);
    const requestData = {
      type: metaType,
      studyId: studyID,
      data: JSON.parse(sendfieldValue)
    };
    if (editID.current !== 0) {
      AppSMService.axios
        .patch(`${STUDY}/${studyID}/config/${editID.current}`, requestData)
        .then(({ status }) => {
          if (status === HTTP_STATUS_CODE.OK) {
            editID.current = 0;
            removeValue();
            loadStudyConfigData();
            handlePopupClose();
            updateState({
              openAlert: true,
              openAlertStatus: SUCCESS,
              openAlertMsg: `${titleName} Updated Success`
            });
          }
        });
    } else {
      AppSMService.axios.post(`${STUDY}/${studyID}/config`, requestData).then(({ status }) => {
        if (status === HTTP_STATUS_CODE.OK) {
          removeValue();
          loadStudyConfigData();
          handlePopupClose();
        }
      });
    }
  };
  const removeValue = () => {
    const updfieldValue: any = [];
    fieldValue.map((item: any) => {
      item['value'] = '';
      updfieldValue.push(item);
    });
    updateState({ fieldValue: updfieldValue });
  };

  const handleCloseDialog = () => {
    updateState({ openDialog: false });
  };
  const handleAcceptDialog = () => {
    AppSMService.axios
      .delete(`study/${studyID}/config/${editID.current}`)
      .then(({ status }) => {
        if (status === HTTP_STATUS_CODE.PROCESSING) {
          loadStudyConfigData();
          updateState({
            openDialog: false,
            openAlertStatus: SUCCESS,
            openAlertMsg: `${titleName} Deleted Success`,
            openAlert: true
          });
        }
      })
      .catch(() => {
        updateState({
          openDialog: false,
          openAlertStatus: ERROR,
          openAlertMsg: `${titleName} Not Deleted`,
          openAlert: true
        });
      })
      .finally(() => {
        updateState({ gridLoading: false });
      });
  };
  const handleCloseAlert = () => {
    updateState({ openAlertStatus: '', openAlertMsg: '', openAlert: false });

    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      updateState({ openAlertStatus: '', openAlertMsg: '', openAlert: false });
    }, 3000);
  };

  const rowsUpdated = metaDataList;
  let titleName = '';
  if (activeTab === 0) {
    titleName = TabHValues.VISIT;
  } else if (activeTab === 1) {
    titleName = TabHValues.SUBJECT;
  } else if (activeTab === 2) {
    titleName = TabHValues.SITE;
  }
  return (
    <React.Fragment>
      <Grid item xs={12} md={8} lg={9}>
        <Breadcrumb breadCrumbList={breadCrumbList} />
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column'
          }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabHeader
              headerlist={tabHeader}
              handleSetActiveTab={handleSetActiveTab}
              selectedTab={activeTab}
            />
          </Box>
          {titleName === TabHValues.SITE ? (
            <Box mb={2} mt={2} display="flex" justifyContent="flex-end" alignItems="flex-end">
              {createSiteMetaDataAccessRights && (
                <>
                  <Button variant="contained" onClick={handleopenDataNewPopup}>
                    Add New {titleName}
                  </Button>

                  <Button
                    variant="contained"
                    onClick={handleopenPopup}
                    style={{ marginLeft: '10px' }}>
                    {titleName} Config
                  </Button>
                </>
              )}
            </Box>
          ) : (
            <Box mb={2} mt={2} display="flex" justifyContent="flex-end" alignItems="flex-end">
              {titleName !== TabHValues.SUBJECT && (
                <Button variant="contained" onClick={handleopenDataNewPopup}>
                  Add New {titleName}
                </Button>
              )}

              <Button variant="contained" onClick={handleopenPopup} style={{ marginLeft: '10px' }}>
                {titleName} Config
              </Button>
            </Box>
          )}

          <div style={{ width: '100%' }}>
            <DataGrid
              rows={rowsUpdated}
              columns={columns}
              editMode="row"
              disableRowSelectionOnClick
              disableColumnSelector
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 }
                }
              }}
              pageSizeOptions={[5, 10]}
              style={{ width: '100%', height: rowsUpdated.length>0 ?"100%":"340px" }}
            />
          </div>
        </Paper>
      </Grid>

      <Modal
        open={openModle}
        onClose={handleopenPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 1000,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24
          }}>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {titleName} Config
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              overflowY: 'scroll',
              overflowX: 'none',
              height: '70%'
            }}>
            <FormControl
              sx={{
                m: 1,
                width: '90%',
                minHeight: '80px',
                marginTop: '30px',
                marginLeft: '20px',
                paddingRight: '50px'
              }}>
              {fieldList.length > 0 &&
                fieldList.map((item, index) => {
                  return (
                    <Grid item xs={12} key={index}>
                      <TextField
                        label="Enter Label"
                        value={item.label}
                        name="label"
                        onChange={(event) => handleTextFieldChange(event, index)}
                        sx={{ width: '30%', marginRight: '20px' }}
                        variant="standard"
                        error={item.errorlabel}
                        helperText={item.errorlabel && FIELD_REQUIRED}
                      />
                      <TextField
                        label="Enter Name"
                        value={item.name}
                        name="name"
                        variant="standard"
                        sx={{ marginRight: '20px', width: '30%' }}
                        onChange={(event) => handleTextFieldChange(event, index)}
                        disabled={metaDataList.length === 0 ? false : !item.newconfig}
                        error={item.errorname}
                        helperText={item.errorname && FIELD_REQUIRED}
                      />

                      <FormControl
                        variant="standard"
                        sx={{ width: '28%', marginRight: '20px' }}
                        error={item.errortype}>
                        <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select"
                          value={item.type}
                          name="type"
                          onChange={(event) => handleSelectFieldChange(event, index)}
                          error={item.errorlabel}>
                          <MenuItem value={'Text'}>Text</MenuItem>
                          <MenuItem value={'Number'}>Number</MenuItem>
                          <MenuItem value={'Date'}>Date</MenuItem>
                          <MenuItem value={'Time'}>Time</MenuItem>
                        </Select>
                        {item.errortype && <FormHelperText>This is required!</FormHelperText>}
                      </FormControl>

                      {fieldList.length > 1 && item.newconfig ? (
                        <CancelIcon
                          sx={{ marginTop: '25px' }}
                          onClick={() => handleRemoveConfig(index)}
                        />
                      ) : (
                        fieldList.length > 1 &&
                        metaDataList.length === 0 && (
                          <CancelIcon
                            sx={{ marginTop: '25px' }}
                            onClick={() => handleRemoveConfig(index)}
                          />
                        )
                      )}
                    </Grid>
                  );
                })}
            </FormControl>
          </Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center">
            <Button onClick={addNewConfig}>
              <AddIcon /> Add
            </Button>
          </Grid>
          <Stack
            direction="row"
            alignItems="right"
            justifyContent="right"
            spacing={2}
            pt={4}
            pb={2}
            pr={5}>
            <Button variant="outlined" onClick={handlePopupClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={addConfigField}>
              Add
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={openDataModle}
        /*  onClose={handleopenPopup} */
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24
          }}>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {editID.current !== 0 ? 'Update' : 'Add'} {titleName}
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              overflowY: 'scroll',
              overflowX: 'none',
              height: '70%'
            }}>
            <FormControl
              sx={{
                m: 1,
                width: '90%',
                minHeight: '80px',
                marginTop: '10px',
                marginLeft: '20px',
                paddingRight: '50px'
              }}>
              {fieldValue.length > 0 &&
                fieldValue.map((item: any, index: number) => {
                  return (
                    <Grid item xs={12} key={index} sx={{ mb: 2 }}>
                      {(item.type === 'Text' || item.type === 'Number') && (
                        <TextField
                          label={item.label}
                          value={item.value}
                          type={item.type === 'Number' ? 'number' : 'text'}
                          onChange={(event) => handleTextValueChange(event, index)}
                          sx={{ width: '100%' }}
                          variant="standard"
                          error={item.errorname}
                          helperText={item.errorname && FIELD_REQUIRED}
                        />
                      )}
                      {item.type === 'Date' && (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{ width: '100%' }}
                            onChange={(date) => {
                              updateValue(new Date(date), index);
                            }}
                            value={item.value}
                          />
                        </LocalizationProvider>
                      )}
                      {item.type === 'Time' && (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label="Basic time picker"
                            sx={{ width: '100%' }}
                            onChange={(date) => {
                              updateValue(new Date(date), index);
                            }}
                            value={item.value}
                          />
                        </LocalizationProvider>
                      )}
                    </Grid>
                  );
                })}
            </FormControl>
          </Box>
          <Stack
            direction="row"
            alignItems="right"
            justifyContent="right"
            spacing={2}
            pt={4}
            pb={2}
            pr={5}>
            <Button variant="outlined" onClick={handlePopupClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={addValue}>
              {editID.current !== 0 ? 'Update' : 'Add'}
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Dialog
        openDialog={openDialog}
        dialogMsg={'Are you sure want remove this study?'}
        dialogHeader={'Alert'}
        handleCloseDialog={handleCloseDialog}
        handleAcceptDialog={handleAcceptDialog}
      />
      <Alert
        openAlertStatus={openAlertStatus}
        openAlert={openAlert}
        openAlertMsg={openAlertMsg}
        handleCloseAlert={handleCloseAlert}
      />
    </React.Fragment>
  );
};

export default Metadata;
