import React, { useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { Grid, Paper, Box, Typography, Button, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Alert from "../../molecules/Alert/Alert";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useDataValidation } from "../../../utils/useDataValidation";
import { AppUMService } from "../../../network/useAxios";
import {
  HTTP_STATUS_CODE,
  NETWORK_CONSTANTS,
} from "../../../network/networkConstants";
import {
  InitialStateTypes,
  updateLoginData,
  updateStudyRoleData,
} from "../../../store/slices/globalSlice";
import {
  useDataUtils,
  ROUTING_CONSTANTS,
  TEXT_CONSTANTS,
} from "../../../utils";
import { STORE_CONFIG } from "../../../store/storeConstant";

function Login() {
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const dispatch: any = useAppDispatch();
  const { checkIsEmpty, checkIsEqual } = useDataValidation();
  const { getRandomNumber } = useDataUtils();
  const { resetID } = useParams();
  const navigate = useNavigate();
  const [loginState, setLoginState] = useState({
    openAlert: false,
    pwdCreate: false,
    userId: "",
    openAlertMsg: "",
    openAlertStatus: "",
    userNameError: false,
    userPWDError: false,
    forgotPWD: false,
    emailError: false,
    updatePWD: "",
    updateConfirmPWD: "",
    resetPWDError: false,
    resetConfPWDError: "",
  });
  const {
    VALIDATION_TEXT: {
      COMMON: {
        ERROR,
        SUCCESS,
        FIELD_REQUIRED,
        MAIL_SEND,
        SOMETHING_WENT_WRONG,
      },
      LOGIN: {
        LOGIN_FAILED,
        LOGIN_SUCCESS,
        PASSWORD_NOT_MATCHING,
        PASSWORD_UPDATED_SUCCESSFULLY,
        USER_NOT_UPDATED,
      },
    },
    COMMON: {
      TOKEN,
      FORM: {
        ELEMENT_TYPE: { TYPE_EMAIL, TYPE_PASSWORD },
      },
    },
    FORM: {
      LOGIN: {
        EMAIL,
        BACK_TO_LOGIN,
        CHANGE_PASSWORD_LABEL,
        CONFIRM_PASSWORD,
        CONFIRM_PASSWORD_LABEL,
        EMAIL_LABEL,
        FORGOT_EMAIL,
        FORGOT_PASSWORD,
        PASSWORD,
        PASSWORD_LABEL,
        RESET_PASSWORD,
        REST_PASSWORD_LABEL,
        SIGN_IN_LABEL,
        SUBMIT,
        UPDATE_PASSWORD,
        USER_NAME_LABEL,
      },
    },
  } = TEXT_CONSTANTS;
  const {
    END_POINTS: {
      AUTH_TOKEN,
      USER_ME,
      AUTH_FORGOT_PASSWORD,
      AUTH_REST_PASSWORD,
    },
  } = NETWORK_CONSTANTS;
  const updateState = (data: any) => {
    setLoginState((prevState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };

  function checkPreLoginValidation(formData: FormData) {
    if (
      checkIsEmpty(formData.get(EMAIL)) &&
      checkIsEmpty(formData.get(PASSWORD))
    ) {
      updateState({ userNameError: true, userPWDError: true });
      return false;
    } else if (checkIsEmpty(formData.get(EMAIL))) {
      updateState({ userNameError: true });
      return false;
    } else if (checkIsEmpty(formData.get(PASSWORD))) {
      updateState({ userPWDError: true });
      return false;
    }
    return true;
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const emailValue = formData.get(EMAIL);
    const passwordValue = formData.get(PASSWORD);

    if (checkPreLoginValidation(formData)) {
      try {
        const { data: loginData, status: loginStatus } =
          await AppUMService.axios.post(AUTH_TOKEN, {
            username: emailValue,
            password: passwordValue,
          });

        if (loginStatus === 200) {
          localStorage.setItem(TOKEN, loginData.jwt);

          dispatch(updateLoginData({ jwt: loginData.jwt, isLoggedIn: true }));
          const { data: userData, status: userStatus } =
            await AppUMService.axios.get(USER_ME);
          if (userStatus === 200) {
            dispatch(updateStudyRoleData(userData.studyRole));
            updateState({
              pwdCreate: true,
              userId: userData.principal.id,
              openAlert: true,
              openAlertStatus: SUCCESS,
              openAlertMsg: LOGIN_SUCCESS,
            });
            if (userData.principal.firstLogin === true) {
              updateState({
                pwdCreate: true,
                userId: userData.data.principal.id,
              });
            } else {
              navigate(ROUTING_CONSTANTS.DASHBOARD);
            }
            AutoCloseAlert();
          }
        }
      } catch (error) {
        updateState({
          openAlert: true,
          openAlertStatus: ERROR,
          openAlertMsg: LOGIN_FAILED,
        });
        AutoCloseAlert();
        console.log(`Error in Login - ${error}`);
      }
    }
  };
  const handleCloseAlert = () => {
    updateState({ openAlert: false, openAlertStatus: "", openAlertMsg: "" });
    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      updateState({ openAlert: false, openAlertStatus: "", openAlertMsg: "" });
    }, 3000);
  };
  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    switch (name) {
      case EMAIL:
        updateState({ userNameError: checkIsEmpty(value) });
        break;
      case PASSWORD:
        updateState({ userPWDError: checkIsEmpty(value) });
        break;
      case RESET_PASSWORD:
        updateState({ resetPWDError: checkIsEmpty(value) });
        break;
      case UPDATE_PASSWORD:
        updateState({
          updatePWD: value,
          userPWDError: checkIsEmpty(value),
        });
        break;
      case CONFIRM_PASSWORD:
        updateState({
          updateConfirmPWD: value,
          resetConfPWDError: checkIsEmpty(value) ? "" : FIELD_REQUIRED,
        });
        break;
      case FORGOT_EMAIL:
        updateState({ userNameError: checkIsEmpty(value) });
        break;
      default:
        break;
    }
  };
  const openForgotPwd = () => {
    updateState({
      userNameError: true,
      userPWDError: false,
      forgotPWD: !loginState.forgotPWD,
    });
  };

  function checkPreValidationForForgotPassword(formData: FormData) {
    if (checkIsEmpty(formData.get(FORGOT_EMAIL))) {
      updateState({ emailError: true });
      return false;
    }
    return true;
  }

  const handleForgotSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const forgotEmail = formData.get(FORGOT_EMAIL) || "";
    if (checkPreValidationForForgotPassword(formData)) {
      const { status } = await AppUMService.axios.post(AUTH_FORGOT_PASSWORD, {
        recipient: forgotEmail,
        accessKey: getRandomNumber(),
        resetURL: `${window.location.href}${RESET_PASSWORD}/`,
      });
      if (status === 200) {
        AutoCloseAlert();
        const forgotemail = document.getElementById(
          FORGOT_EMAIL
        ) as HTMLInputElement;
        if (forgotemail) {
          forgotemail.value = "";
        }
        updateState({
          openAlert: true,
          openAlertStatus: SUCCESS,
          openAlertMsg: MAIL_SEND,
          forgotPWD: false,
        });
      } else {
        updateState({
          openAlert: true,
          openAlertStatus: ERROR,
          openAlertMsg: SOMETHING_WENT_WRONG,
          forgotPWD: false,
        });
        AutoCloseAlert();
      }
    }
  };
  function checkPreValidationForResetPassword(formData: FormData) {
    const restPassword = formData.get(RESET_PASSWORD);
    const confirmPassword = formData.get(RESET_PASSWORD);
    if (checkIsEmpty(restPassword)) {
      updateState({ resetPWDError: true });
      return false;
    } else if (checkIsEmpty(confirmPassword)) {
      updateState({
        resetConfPWDError: FIELD_REQUIRED,
      });
      return false;
    } else if (!checkIsEqual(restPassword, confirmPassword)) {
      updateState({
        resetConfPWDError: PASSWORD_NOT_MATCHING,
      });
      return false;
    }
    return true;
  }

  const handleSubmitResetPwd = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const restPassword = formData.get(RESET_PASSWORD);

    if (checkPreValidationForResetPassword(formData)) {
      const { status } = await AppUMService.axios.post(
        `${AUTH_REST_PASSWORD}${resetID}&password=${restPassword}`
      );
      if (status === HTTP_STATUS_CODE.OK) {
        const elemResetPassword = document.getElementById(
          RESET_PASSWORD
        ) as HTMLInputElement;
        if (elemResetPassword) {
          elemResetPassword.value = "";
        }
        const elemConfirmPassword = document.getElementById(
          CONFIRM_PASSWORD
        ) as HTMLInputElement;
        if (elemConfirmPassword) {
          elemConfirmPassword.value = "";
        }
        updateState({
          openAlert: true,
          openAlertStatus: SUCCESS,
          openAlertMsg: PASSWORD_UPDATED_SUCCESSFULLY,
        });
        AutoCloseAlert();
        setTimeout(() => {
          navigate(ROUTING_CONSTANTS.ROOT);
        }, 3000);
      } else {
        updateState({
          openAlert: true,
          openAlertStatus: ERROR,
          openAlertMsg: SOMETHING_WENT_WRONG,
        });
        AutoCloseAlert();
      }
    }
  };
  function checkPreValidationForUpdatePassword(formData: FormData) {
    const updatePassword = formData.get(UPDATE_PASSWORD);
    const confirmPassword = formData.get(CONFIRM_PASSWORD);
    if (checkIsEmpty(updatePassword)) {
      updateState({ resetPWDError: true });
      return false;
    } else if (checkIsEmpty(confirmPassword)) {
      updateState({
        resetConfPWDError: FIELD_REQUIRED,
      });
      return false;
    } else if (!checkIsEqual(updatePassword, confirmPassword)) {
      updateState({
        resetConfPWDError: PASSWORD_NOT_MATCHING,
      });
      return false;
    }
    return true;
  }
  const handleSubmitUpdatePwd = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const updatePassword = formData.get(UPDATE_PASSWORD);

    if (checkPreValidationForUpdatePassword(formData)) {
      const { status } = await AppUMService.axios.put(
        `${ROUTING_CONSTANTS.USERS1}${loginState.userId}`,
        { password: updatePassword, firstLogin: false }
      );
      if (status === HTTP_STATUS_CODE.OK) {
        navigate(ROUTING_CONSTANTS.DASHBOARD);
      } else {
        updateState({
          openAlert: true,
          openAlertStatus: ERROR,
          openAlertMsg: USER_NOT_UPDATED,
        });
        AutoCloseAlert();
      }
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        component={Paper}
        elevation={6}
        square
        sx={{
          backgroundImage: "url(/images/clinical-trials.jpeg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          width: "100%",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundPosition: "center",
        }}
      >
        <Grid
          xs={12}
          sm={8}
          md={6}
          item
          style={{ float: "right", color: "#FFFFFF" }}
        >
          {resetID !== "" &&
          typeof resetID !== "undefined" &&
          typeof resetID !== "undefined" ? (
            <Box
              sx={{
                my: 20,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              className="login-form"
            >
              <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                {REST_PASSWORD_LABEL}
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmitResetPwd}
                sx={{ mt: 1 }}
                className="login-form"
              >
                <TextField
                  label={PASSWORD_LABEL}
                  variant="outlined"
                  name={RESET_PASSWORD}
                  id={RESET_PASSWORD}
                  type={TYPE_PASSWORD}
                  sx={{
                    width: "100%",
                    marginTop: "20px",
                    background: "#FFFFFF",
                  }}
                  onChange={handleTextFieldChange}
                  error={loginState.resetPWDError}
                  helperText={
                    loginState.resetPWDError === true && FIELD_REQUIRED
                  }
                />

                <TextField
                  label={CONFIRM_PASSWORD}
                  variant="outlined"
                  name={CONFIRM_PASSWORD}
                  id={CONFIRM_PASSWORD}
                  type={TYPE_PASSWORD}
                  sx={{ width: "100%", marginTop: "20px" }}
                  onChange={handleTextFieldChange}
                  error={loginState.resetConfPWDError !== "" ? true : false}
                  helperText={loginState.resetConfPWDError}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {REST_PASSWORD_LABEL}
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href={"/"} variant="body2" style={{ color: "#FFFF" }}>
                      {BACK_TO_LOGIN}?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ) : loginState.forgotPWD === false ? (
            <Box
              sx={{
                my: 20,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              className="login-form"
            >
              <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              {loginState.pwdCreate === true ? (
                <>
                  <Typography component="h1" variant="h5">
                    {CHANGE_PASSWORD_LABEL}
                  </Typography>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmitUpdatePwd}
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      label={PASSWORD_LABEL}
                      variant="outlined"
                      name={UPDATE_PASSWORD}
                      id={UPDATE_PASSWORD}
                      type={TYPE_PASSWORD}
                      value={loginState.updatePWD}
                      sx={{ width: "100%", marginTop: "20px" }}
                      onChange={handleTextFieldChange}
                      error={loginState.userPWDError}
                      helperText={
                        loginState.userPWDError === true && FIELD_REQUIRED
                      }
                    />
                    <TextField
                      label={CONFIRM_PASSWORD_LABEL}
                      variant="outlined"
                      name={CONFIRM_PASSWORD}
                      id={CONFIRM_PASSWORD}
                      type={TYPE_PASSWORD}
                      value={loginState.updateConfirmPWD}
                      sx={{ width: "100%", marginTop: "20px" }}
                      onChange={handleTextFieldChange}
                      error={loginState.resetConfPWDError !== "" ? true : false}
                      helperText={loginState.resetConfPWDError}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      {CHANGE_PASSWORD_LABEL}
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Typography component="h1" variant="h5">
                    {SIGN_IN_LABEL}
                  </Typography>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      label={USER_NAME_LABEL}
                      variant="outlined"
                      type={TYPE_EMAIL}
                      name={EMAIL}
                      sx={{ width: "100%" }}
                      onChange={handleTextFieldChange}
                      error={loginState.userNameError}
                      helperText={
                        loginState.userNameError === true &&
                        "This field is required"
                      }
                    />
                    <TextField
                      label={PASSWORD_LABEL}
                      variant="outlined"
                      name={PASSWORD}
                      type={TYPE_PASSWORD}
                      sx={{ width: "100%", marginTop: "20px" }}
                      onChange={handleTextFieldChange}
                      error={loginState.userPWDError}
                      helperText={
                        loginState.userPWDError === true && FIELD_REQUIRED
                      }
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      {SIGN_IN_LABEL}
                    </Button>
                    <Grid container>
                      <Grid item xs>
                        <Link
                          href={void 0}
                          onClick={openForgotPwd}
                          variant="body2"
                          style={{ color: "#FFFF" }}
                        >
                          {FORGOT_PASSWORD}?
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                my: 20,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              className="login-form"
            >
              <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                {FORGOT_PASSWORD}
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleForgotSubmit}
                sx={{
                  mt: 1,
                  width: "100%",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <TextField
                  label={EMAIL_LABEL}
                  variant="outlined"
                  name={FORGOT_EMAIL}
                  id={FORGOT_EMAIL}
                  sx={{ width: "100%" }}
                  onChange={handleTextFieldChange}
                  error={loginState.userNameError}
                  helperText={
                    loginState.userNameError === true && FIELD_REQUIRED
                  }
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {SUBMIT}
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link
                      href={void 0}
                      onClick={openForgotPwd}
                      variant="body2"
                      style={{ color: "#FFFF" }}
                    >
                      {BACK_TO_LOGIN}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Grid>
        <Alert
          openAlertStatus={loginState.openAlertStatus}
          openAlert={loginState.openAlert}
          openAlertMsg={loginState.openAlertMsg}
          handleCloseAlert={handleCloseAlert}
        />
      </Grid>
    </Grid>
  );
}

export default Login;
