import { useDataValidation } from './useDataValidation';
import { FIND_KEYS, USER_TYPE } from './appConstants';
import { useAppSelector } from '../hooks';
import { InitialStateTypes } from '../store/slices/globalSlice';
import { STORE_CONFIG } from '../store/storeConstant';
export enum ACCESS_RIGHTS {
  CREATE_FORM = 'cForm',
  CREATE_SITE_META_DATA = 'cSiteMetaData',
  CREATE_SUBJECT_META_DATA = 'cSubMetaData',
  DATA_ENTRY_MENU = 'dataCollection',
  REPLY_QUERY = 'rQuery',
  DATA_ENTRY_VIEW = 'dataView',
  FORM_REVIEW = 'reviewForm'
}
export function useStudyAccessRights() {
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const {
    studyRole,
    study: { mode },
    user: { type }
  } = appDataContext;
  const { find } = useDataValidation();
  const checkAccessRights = function (accessData: string) {
    const modeObject = find(studyRole.mode, mode, FIND_KEYS.mode.name) ?? {};
    const studyAccessRights = modeObject?.accessRights ?? [];
    return type === USER_TYPE.CLIENT ? true : studyAccessRights.includes(accessData);
  };

  const createFormAccessRights = checkAccessRights(ACCESS_RIGHTS.CREATE_FORM);
  const createSiteMetaDataAccessRights = checkAccessRights(ACCESS_RIGHTS.CREATE_SITE_META_DATA);
  const createSubjectMetaDataAccessRights = checkAccessRights(
    ACCESS_RIGHTS.CREATE_SUBJECT_META_DATA
  );
  const dataEntryMenuAccessRights = checkAccessRights(ACCESS_RIGHTS.DATA_ENTRY_MENU);
  const replayQueryAccessRights = checkAccessRights(ACCESS_RIGHTS.REPLY_QUERY);
  const dataViewAccessRights = checkAccessRights(ACCESS_RIGHTS.DATA_ENTRY_VIEW);
  const formReviewAccessRights = checkAccessRights(ACCESS_RIGHTS.FORM_REVIEW);
  return {
    checkAccessRights,
    createFormAccessRights,
    createSiteMetaDataAccessRights,
    createSubjectMetaDataAccessRights,
    dataEntryMenuAccessRights,
    replayQueryAccessRights,
    dataViewAccessRights,
    formReviewAccessRights
  };
}
