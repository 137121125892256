
export enum TabHValues {
  VISIT="Visit",
  SUBJECT="Subject",
  SITE ="Site"
}
export enum META_DATA_TYPE {
  VISIT="VISIT",
  SUBJECT="SUBJECT",
  SITE ="SITE"
}

interface Field{
  name: "",
  errorname: false,
  label: "",
  errorlabel: false,
  type: "",
  errortype: false,
  newconfig: true,
}


export interface MetaDataState {
  metaDataList:Array<any>;
  metaDataMainList:Array<any>
  activeTab:number;
  configID:string;
  open:boolean;
  fieldName:Array<any>;
  fieldsTempName:Array<any>;
  fieldList:Array<Field>;
  fieldValue:Array<any>;
  randomNumber:number;
  randUpdate:number;
  btnloading:boolean;
  openAlert:boolean;
  openAlertMsg:string;
  openAlertStatus:string;
  type:string;
  openDialog:boolean;
  gridLoading:boolean;
  anchorEl:null | HTMLElement;
  openModle:boolean;
  openDataModle:boolean;
}

