import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Grid,
  Paper,
  Menu,
  MenuItem,
  Button,
  Box,
  FormControl,
  Typography,
  TextField,
  Stack,
  FormHelperText,
} from "@mui/material";
import { SaveOutlined } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Modal from "@mui/material/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import MoreIcon from "@mui/icons-material/MoreVert";
import Breadcrumb from "../../organisms/Breadcrumb/Breadcrumb";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Alert from "../../molecules/Alert/Alert";
import Dialog from "../../molecules/Dialog/Dialog";
import {
  BREAD_CRUMBS,
  LOGIN_MODES,
  ROUTING_CONSTANTS,
  TEXT_CONSTANTS,
  USER_TYPE,
} from "../../../utils/appConstants";
import { StudyState } from "./types";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { STORE_CONFIG } from "../../../store/storeConstant";
import { InitialStateTypes, updateClientData, updateMetaData, updateStudyData } from "../../../store/slices/globalSlice";
import {
  HTTP_STATUS_CODE,
  NETWORK_CONSTANTS,
} from "../../../network/networkConstants";
import { AppSMService, AppUMService } from "../../../network/useAxios";
import { useDataValidation } from "../../../utils";
import { MenuProps, STUDY_GRID_COLUMN_CLIENT } from "./utils";

const Study = () => {
  const navigate = useNavigate();
  const { clientID } = useParams();
  const { HOME, CLIENT, STUDY_MANAGEMENT, PROJECT_MANAGEMENT } = BREAD_CRUMBS;
  const dispatch: any = useAppDispatch();
  const { DASHBOARD, CLIENT: RCLIENT, PROJECT,STUDY_ACCESS,STUDY_DETAILS } = ROUTING_CONSTANTS;
  const breadCrumbList = [
    { title: HOME, link: DASHBOARD },
    { title: CLIENT, link: RCLIENT },
    { title: STUDY_MANAGEMENT, link: `${PROJECT}${clientID}` },
    { title: PROJECT_MANAGEMENT, link: "" },
  ];
  const {
    VALIDATION_TEXT: {
      COMMON: { FIELD_REQUIRED, ERROR, SOMETHING_WENT_WRONG, SUCCESS },
      STUDY_PAGE: {
        STUDY_PERCENTAGE_MAX_100,
        STUDY_CREATE_SUCCESS,
        STUDY_DELETION_ERROR,
        STUDY_DELETION_SUCCESS,
        STUDY_UPDATE_SUCCESS,
      },
    },
  } = TEXT_CONSTANTS;
  const { checkIsEmpty } = useDataValidation();
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { project, user,client } = appDataContext;
  const { STUDY, USERS } = NETWORK_CONSTANTS.END_POINTS;
  const [studyState, setStudyState] = useState<StudyState>({
    userList: [],
    studList: [],
    studyName: null,
    studyMinPercentage: "0",
    studyNameError: false,
    studyMinPercentageError: null,
    dateFormat: "dd/mm/yyyy",
    studyUserError: false,
    options: [],
    btnloading: false,
    openAlert: false,
    openAlertMsg: "",
    openAlertStatus: "",
    type: null,
    openDialog: false,
    gridLoading: false,
    loginMode: "",
    popupType: "",
    optionUser: [],
    randomNumber: 0,
    openModal: false,
  });
  const {
    btnloading,
    dateFormat='dd/mm/yyyy',
    gridLoading,
    loginMode,
    openAlert,
    openAlertMsg,
    openAlertStatus,
    openDialog,
    optionUser,
    options,
    popupType,
    studList,
    studyMinPercentage,
    studyMinPercentageError,
    studyName,
    studyNameError,
    studyUserError,
    type,
    userList,
    openModal,
  } = studyState;

  const editID = useRef("");
  const editstudyName = useRef("");
  const editreviewers = useRef("");
  const editunfreeze = useRef(false);
  const editreviewMinPercentage = useRef("");
  const editactive = useRef(true);

  const updateState = (data: any) => {
    setStudyState((prevState: StudyState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };

  React.useEffect(() => {
    loadStudy();
    loadUsers();
   
  }, []);

  const loadStudy = () => {
    AppSMService.axios
      .get(`${STUDY}?projectId=${project.id}`)
      .then(({ status, data }) => {
        if (status === HTTP_STATUS_CODE.OK) {
          updateState({
            studList:
              data.length > 0 && status === HTTP_STATUS_CODE.OK ? data : [],
            gridLoading: false,
          });
        }
      })
      .catch(() => {
        updateState({
          gridLoading: false,
        });
      });
  };
  const loadUsers = () => {
    const requestParams = `&clientUserName=${user?.username}`;
    AppUMService.axios
      .get(`${USERS}?userType=${USER_TYPE.CLIENT_USER}&sortBy=createdDate${requestParams}`)
      .then(({ status, data }) => {
        updateState({
          userList:
            data.length > 0 && status === HTTP_STATUS_CODE.OK ? data : [],
        });
      });
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    params: GridRenderCellParams
  ) => {
    editID.current = params.row.id;
    updateState({
      popupType: "study",
    });
    setAnchorEl(event.currentTarget);
  };
  const handleLoginClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    params: GridRenderCellParams
  ) => {
    const {
      row: { id, name, reviewers, reviewMinPercentage, unfreeze, active },
    } = params;
    editID.current = id;
    editstudyName.current = name;
    editreviewers.current = reviewers;
    editreviewMinPercentage.current = reviewMinPercentage;
    editunfreeze.current = unfreeze;
    editactive.current = !checkIsEmpty(active) ? active : true;
    updateState({
      popupType: "studylogin",
    });
    if (user?.type !== USER_TYPE.ADMIN) {
      handleopenPopup();
    } else {
      loginStudy();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    AppSMService.axios
      .get(`${STUDY}/${editID.current}`)
      .then(({ status, data }) => {
        if (status === HTTP_STATUS_CODE.OK) {
          updateState({
            studyName: data.name,
            studyMinPercentage: data.reviewMinPercentage,
            options: data.reviewers,
            type: "Edit",
          });
          handleopenPopup();
          handleClose();
        }
      });
  };

  const handleopenPopup = () => {
    updateState({
      studyNameError: false,
      studyMinPercentageError: "",
      openModal: !openModal,
    });
  };
  const handleopenNewPopup = () => {
    updateState({
      studyName: "",
      studyMinPercentage: "",
      options: [],
      type: "Add",
      popupType: "study",
      studyNameError: false,
      studyMinPercentageError: "",
      openModal: true,
    });

    editID.current = "";
  };

  const columns: GridColDef[] = [
    ...STUDY_GRID_COLUMN_CLIENT,
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params: GridRenderCellParams): React.ReactNode => {
        return (
          <>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => handleClick(event, params)}
            >
              <MoreIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClickOpen}>Edit</MenuItem>
              <MenuItem onClick={handleRemove}>Remove</MenuItem>
            </Menu>
          </>
        );
      },
    },
    {
      field: "studylogin",
      headerName: "Study Login",
      width: 100,
      renderCell: (params: GridRenderCellParams): React.ReactNode => {
        return (
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => handleLoginClick(event, params)}
          >
            Login
          </Button>
        );
      },
    },
  ];

  const handleSelectFieldChange = (
    event: SelectChangeEvent<typeof options>
  ) => {
    const {
      target: { value, name },
    } = event;
    const _value = typeof value === "string" ? value.split(",") : value;
    const _updateData: any = {};

    updateState({
      optionUser: name === "user" ? _value : optionUser,
      dateFormat: name === "dateformat" ? value : dateFormat,
      options: _value,
    });
    if (name === "user") {
      _updateData["optionUser"] = _value;
    }
    if (name === "dateformat") {
      _updateData["dateFormat"] = value;
    } else {
      _updateData["options"] = _value;
      if (name === "reviewers") {
        _updateData["studyUserError"] = value.length <= 0;
      }
    }
    updateState(_updateData);
  };
  const handleChangeMode = (event: SelectChangeEvent<typeof options>) => {
    const {
      target: { value },
    } = event;
    updateState({ loginMode: value });
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    const name = event.target.name;
    if (name === "studyName") {
      updateState({ studyName: value, studyNameError: false });
    } else if (name === "studyMinPercentage") {
      updateState({ studyMinPercentage: value, studyMinPercentageError: "" });
    }
  };
  function checkPreValidationForSubmitStudy() {
    if (checkIsEmpty(studyNameError)) {
      updateState({ studyNameError: true });
      return true;
    } else if (checkIsEmpty(studyMinPercentage)) {
      updateState({ studyMinPercentageError: FIELD_REQUIRED });
    } else if (parseInt(studyMinPercentage) > 100) {
      updateState({ studyMinPercentageError: STUDY_PERCENTAGE_MAX_100 });
    }
    return false;
  }
  const submitStudy = async () => {
    if (checkPreValidationForSubmitStudy()) {
      updateState({ btnloading: true });
      let response: any = { status: HTTP_STATUS_CODE.OK };
      if (!checkIsEmpty(editID.current)) {
        response = await AppSMService.axios.patch(
          `${STUDY}/${editID.current}`,
          {
            name: studyName,
            reviewMinPercentage: studyMinPercentage,
            reviewers: options,
            dateFormat: dateFormat,
          }
        );
      } else {
        response = await AppSMService.axios.post(`${STUDY}`, {
          name: studyName,
          reviewMinPercentage: studyMinPercentage,
          reviewers: options,
          projectId: project.id,
          dateFormat: dateFormat,
        });
      }
      if (HTTP_STATUS_CODE.OK === response.status) {
        updateState({
          openAlert: true,
          openAlertStatus: SUCCESS,
          openAlertMsg: !checkIsEmpty(editID.current) ? STUDY_UPDATE_SUCCESS:STUDY_CREATE_SUCCESS,
          studyName: "",
          studyMinPercentage: "",
          options: [],
          btnloading: false,
        });
        loadStudy();
        handleopenPopup();
      } else {
        updateState({
          openAlert: true,
          openAlertStatus: ERROR,
          openAlertMsg: SOMETHING_WENT_WRONG,
        });
      }
      AutoCloseAlert();
    }
  };
  const handleRemove = () => {
    updateState({
      openDialog:true
    });
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    updateState({
      openDialog:false
    });
  };
  const handleAcceptDialog = () => {
    AppSMService.axios
      .delete(`${STUDY}/${editID.current}`)
      .then(({status}) => {
        if (status === HTTP_STATUS_CODE.PROCESSING) {
          loadStudy();        
        }
        updateState({
          openDialog:false,
          openAlert:true,
          openAlertStatus:SUCCESS,
          openAlertMsg:STUDY_DELETION_SUCCESS,
          gridLoading:false
        });
      })
      .catch(() => {
        updateState({
          openDialog:false,
          openAlert:true,
          openAlertStatus:ERROR,
          openAlertMsg:STUDY_DELETION_ERROR,
          gridLoading:false
        });
      });
  };

  const handleCloseAlert = () => {
    updateState({
      openAlert:false,
      openAlertStatus:"",
      openAlertMsg:"",
    });
    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      updateState({
        openAlert:false,
        openAlertStatus:"",
        openAlertMsg:"",
      });
    }, 3000);
  };
  const loginStudy = () => {
    dispatch(updateStudyData({
      id: editID.current,
      mode: loginMode,
      name: editstudyName.current,
      reviewers: editreviewers.current,
      reviewMinPercentage: editreviewMinPercentage.current,
      unfreeze: editunfreeze.current,
      active: editactive.current,
    }));
    dispatch(updateMetaData( {
      clientId:
        user?.type ===  USER_TYPE.CLIENT ? user?.id : client.id,
      projectId: project.id,
      studyId: editID.current,
    }))
    
   
    if (user?.type === USER_TYPE.CLIENT) {
      dispatch(updateClientData( {
        id: user?.id,
      }))
    }
    handleopenPopup();
    updateState({randomNumber:Math.random()});
    navigate(loginMode === "STUDY SETTINGS" ?STUDY_ACCESS:STUDY_DETAILS );
  };

  const rowsUpdated = studList;
  return (
    <React.Fragment>
      <Grid item xs={12} md={8} lg={9}>
        <Breadcrumb breadCrumbList={breadCrumbList} />
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            mb={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button variant="contained" onClick={handleopenNewPopup}>
              Add Study
            </Button>
          </Box>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rowsUpdated}
              columns={columns}
              disableRowSelectionOnClick
              disableColumnSelector
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              loading={gridLoading}
              localeText={{
                noRowsLabel:
                  gridLoading === false && rowsUpdated.length === 0
                    ? `No Study Found`
                    : "",
              }}
            />
          </div>
        </Paper>
      </Grid>

      <Modal
        open={openModal}
        onClose={handleopenPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
          }}
        >
          {popupType === "study" && (
            <>
              <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                  <Toolbar>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ flexGrow: 1 }}
                    >
                      {type === "Edit" ? "Update" : "Add New"} Study
                    </Typography>
                  </Toolbar>
                </AppBar>
              </Box>
              <FormControl
                sx={{
                  m: 1,
                  width: "80%",
                  marginTop: "30px",
                  marginLeft: "20px",
                }}
              >
                <Grid item xs={12}>
                  <TextField
                    label="Study Name"
                    value={studyName}
                    variant="standard"
                    name="studyName"
                    sx={{ marginRight: "20px", width: "60%" }}
                    onChange={handleTextFieldChange}
                    error={studyNameError}
                    helperText={studyNameError && "This field is required"}
                  />
                  <TextField
                    label="Review Min Percentage"
                    value={studyMinPercentage}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                    name="studyMinPercentage"
                    onChange={handleTextFieldChange}
                    sx={{ width: "35%" }}
                    variant="standard"
                    error={studyMinPercentageError !== "" ? true : false}
                    helperText={studyMinPercentageError}
                  />

                  <FormControl
                    variant="standard"
                    sx={{
                      width: "60%",
                      minHeight: "80px",
                      marginTop: "30px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Select Date Format
                    </InputLabel>

                    <Select
                      labelId="demo-multiple-checkbox-label"
                      value={dateFormat}
                      name="dateformat"
                      onChange={handleSelectFieldChange}
                    >
                      <MenuItem value={"dd/yyyy"}>dd/yyyy</MenuItem>
                      <MenuItem value={"dd/mm/yyyy"}>dd/mm/yyyy</MenuItem>
                      <MenuItem value={"mm/dd/yyyy"}>mm/dd/yyyy</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </FormControl>
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  width: "80%",
                  minHeight: "80px",
                  marginLeft: "20px",
                  marginTop: "30px",
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  Select Reviewers
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  multiple
                  value={options}
                  onChange={handleSelectFieldChange}
                  name="reviewers"
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {userList.map((item: any, index: number) => {
                    return (
                      <MenuItem key={index} value={item.username}>
                        <Checkbox
                          checked={options.indexOf(item.username) > -1}
                        />
                        <ListItemText primary={item.username} />
                      </MenuItem>
                    );
                  })}
                </Select>
                {studyUserError === true && (
                  <FormHelperText error={true}>
                    This field is required
                  </FormHelperText>
                )}
              </FormControl>
              <Stack
                direction="row"
                alignItems="right"
                justifyContent="right"
                spacing={2}
                pt={4}
                pb={2}
                pr={5}
              >
                <Button variant="outlined" onClick={handleopenPopup}>
                  Cancel
                </Button>
                <LoadingButton
                  onClick={submitStudy}
                  loading={btnloading}
                  loadingPosition="start"
                  startIcon={<SaveOutlined />}
                  variant="contained"
                >
                  {type === "Edit" ? "Update" : "Add"}
                </LoadingButton>
              </Stack>
            </>
          )}
          {popupType === "studylogin" && (
            <>
              <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                  <Toolbar>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ flexGrow: 1 }}
                    >
                      Study Login
                    </Typography>
                  </Toolbar>
                </AppBar>
              </Box>

              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  width: "80%",
                  minHeight: "80px",
                  marginLeft: "20px",
                  marginTop: "30px",
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  Select Mode
                </InputLabel>

                <Select
                  labelId="demo-multiple-checkbox-label"
                  value={loginMode}
                  name="loginMode"
                  onChange={handleChangeMode}
                >
                  {LOGIN_MODES.map((data:any)=>{
                    return   <MenuItem key={data.value} value={data.value}>{data.name}</MenuItem>
                  })}
                
                </Select>
              </FormControl>
              <Stack
                direction="row"
                alignItems="right"
                justifyContent="right"
                spacing={2}
                pt={4}
                pb={2}
                pr={5}
              >
                <Button variant="outlined" onClick={handleopenPopup}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={btnloading}
                  loadingPosition="start"
                  startIcon={<SaveOutlined />}
                  variant="contained"
                  onClick={loginStudy}
                >
                  Login
                </LoadingButton>
              </Stack>
            </>
          )}
        </Box>
      </Modal>

      <Dialog
        openDialog={openDialog}
        dialogMsg={"Are you sure want remove this study?"}
        dialogHeader={"Alert"}
        handleCloseDialog={handleCloseDialog}
        handleAcceptDialog={handleAcceptDialog}
      />
      <Alert
        openAlertStatus={openAlertStatus}
        openAlert={openAlert}
        openAlertMsg={openAlertMsg}
        handleCloseAlert={handleCloseAlert}
      />
    </React.Fragment>
  );
};

export default Study;
