import { createSlice } from "@reduxjs/toolkit";
import { STORE_CONFIG } from "../storeConstant";
import { IUser } from "../../models/User";
import { IClinicalForm, IFormMetadata } from "../../models/ClinicalForm";
import { AutocompleteOption } from "../../components/molecules/AutoComplete/AutoComplete";

 interface IFilerOption {
  clientsList: AutocompleteOption[];
  projectsList: AutocompleteOption[];
  studyList: AutocompleteOption[];
}

export interface InitialStateTypes {
  user: IUser;
  forms: IClinicalForm[];
  metaData: IFormMetadata; //TODO - temp added the metadata here for initial demo
  optionList: IFilerOption;
  study: any;
  project: any;
  client: any;
  deForms: any;
  logForm: any;
  accessRights: any;
  studyRole: any;
  login: any;
  isLoading: boolean;
}
const initialState: InitialStateTypes = {
  login: { jwt: "", isLoggedIn: false },
  user: {
    id: null,
    name: null,
    type: null,
    username: null,
    authority: null,
    clientUserName: null,
  } as IUser,
  studyRole: {},
  accessRights: [],
  study: {
    id: "",
    mode: "",
    name: "",
    reviewers: "",
    reviewMinPercentage: "",
    unfreeze: false,
    active: true,
  },
  isLoading: false,
  deForms: {
    metaInfo: {},
  },
  logForm: {
    logformReviewFields: {},
  },
  project: {
    id: "",
    name: "",
  },
  client: {
    id: "",
  },
  metaData: {
    clientId: "",
    projectId: "",
    studyId: "",
  },
  forms: [],
  optionList: { clientsList: [], projectsList: [], studyList: [] },
};
const globalSlice = createSlice({
  name: STORE_CONFIG.reducers.appData,
  initialState,
  reducers: {
    updateLoginData(state, action) {
      state.login = { ...state.login, ...action.payload };
    },
    updateStudyRoleData(state, action) {
      state.studyRole = { ...state.studyRole, ...action.payload };
    },
    updateUserData(state, action) {
      state.user = { ...state.user, ...action.payload };
    },
    updateAccessRightsData(state, action) {
      state.accessRights = [...state.accessRights, ...action.payload];
    },
    updateProjectData(state, action) {
      state.project = { ...state.project, ...action.payload };
    },
    updateClientData(state, action) {
      state.client = { ...state.client, ...action.payload };
    },
    updateStudyData(state, action) {
      state.study = { ...state.study, ...action.payload };
    },
    updateDeformData(state, action) {
      state.deForms = { ...state.deForms, ...action.payload };
    },
    updateLogFormData(state, action) {
      state.logForm = { ...state.logForm, ...action.payload };
    },
    updateLoaderState(state, action) {
      state.isLoading = action.payload;
    },
    updateMetaData(state, action) {
      state.metaData = { ...state.metaData, ...action.payload };
    },
    updateFormsData(state, action) {
      state.forms = [...state.forms, ...action.payload];
    },
    updateOptionList(state, action) {
      state.optionList = {...state.optionList, ...action.payload};
    },
    resetStateData() {
      return initialState
    },
  },
});

export const {
  updateLoginData,
  updateUserData,
  updateStudyRoleData,
  updateAccessRightsData,
  updateClientData,
  updateDeformData,
  updateLoaderState,
  updateLogFormData,
  updateProjectData,
  updateStudyData,
  updateMetaData,
  updateFormsData,
  resetStateData,
  updateOptionList
} = globalSlice.actions;
export default globalSlice.reducer;
