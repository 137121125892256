import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, IconButton, Toolbar, Typography, Button } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import Dialog from '../../molecules/Dialog/Dialog';
import { USER_TYPE } from '../../../utils/appConstants';
import { useDataValidation } from '../../../utils/useDataValidation';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  InitialStateTypes,
  updateClientData,
  updateProjectData,
  updateStudyData,
  updateUserData
} from '../../../store/slices/globalSlice';
import { STORE_CONFIG } from '../../../store/storeConstant';

export interface IHeaderProps {
  title: string;
  open: boolean;
  handleOpen: (val: boolean) => void;
}
interface IAppBarProps {
  open: boolean;
}
const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<IAppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));
const Header = (props: IHeaderProps) => {
  const navigate = useNavigate();
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { user, study, project } = appDataContext;
  const dispatch = useAppDispatch();
  const { checkIsEmpty } = useDataValidation();
  const userType = user;
  const toggleDrawer = () => {
    props.handleOpen(!props.open);
  };
  const [openDialog, setopenDialog] = useState(false);
  const [randomNumber, setrandomNumber] = useState(0);
  React.useEffect(() => {
    checkLogin();
  }, []);
  const swithcStudy = () => {
    setopenDialog(true);
  };

  const handleCloseDialog = () => {
    setopenDialog(false);
  };
  const handleAcceptDialog = () => {
    if (userType?.type === USER_TYPE.ADMIN) {
      dispatch(
        updateStudyData({
          id: '',
          mode: '',
          name: ''
        })
      );
      dispatch(
        updateProjectData({
          id: '',
          name: ''
        })
      );
      dispatch(
        updateClientData({
          id: ''
        })
      );

      if (user) {
        dispatch(
          updateUserData({
            clientUserName: ''
          })
        );
      }
    } else {
      dispatch(updateStudyData({
        id: '',
        mode: ''
      }));
    }

    setopenDialog(false);
    navigate(`/clinicalApp/dashboard`);
  };
  const checkLogin = () => {
    setTimeout(() => {
      if (user?.username !== '') {
        setrandomNumber(Math.random());
      } else {
        checkLogin();
      }
    }, 500);
  };

  return (
    <AppBar
      position="absolute"
      open={props.open}
      sx={{ height: study.id !== '' ? '100px' : '56px' }}>
      <Toolbar
        sx={{
          pr: '34px' // keep right padding when drawer closed
        }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(props.open && { display: 'none' })
          }}>
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          {props.title}
        </Typography>
        {user?.authority !== '' && (
          <Typography sx={{ paddingTop: '2px' }}>
            <b>Role:</b> {user?.authority}
          </Typography>
        )}
        <IconButton color="inherit">
          <Badge color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Typography>{userType?.name}</Typography>
      </Toolbar>
      {study.id !== '' && (
        <Toolbar
          sx={{
            color: '#000',
            /* minHeight: "60px !important", */
            backgroundColor: '#FFF',
            textAlign: 'right',
            alignItems: 'flex-start'
          }}>
          <Toolbar
            sx={{
              marginLeft: 'auto'
            }}>
            {project.name !== '' && study.id !== '' && (
              <Typography>
                <b>Project:</b> {project.name}
              </Typography>
            )}
            {study.id !== '' && (
              <Typography sx={{ ml: 1 }}>
                <b>Study:</b> {study.name}
              </Typography>
            )}
            {!checkIsEmpty(study.mode) || user?.type === USER_TYPE.ADMIN ? (
              <>
                {study.mode !== '' && (
                  <Typography sx={{ ml: 1 }}>
                    <b>Mode:</b> {study.mode}
                  </Typography>
                )}
                <Typography sx={{ ml: 1 }}>
                  <Button variant="contained" onClick={swithcStudy}>
                    Switch Study
                  </Button>
                </Typography>
              </>
            ) : null}
          </Toolbar>
        </Toolbar>
      )}
      <Dialog
        openDialog={openDialog}
        dialogMsg={'Are you sure want Switch Study?'}
        dialogHeader={'Alret'}
        handleCloseDialog={handleCloseDialog}
        handleAcceptDialog={handleAcceptDialog}
      />
    </AppBar>
  );
};
export default Header;
