import React, { useState, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  DataGrid,
  GridColDef,
  GridLogicOperator,
  GridRenderCellParams,
  GridToolbarQuickFilter
} from "@mui/x-data-grid";
import {
  Box,
  Grid,
  Paper,
  Menu,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";
import Breadcrumb from "../../organisms/Breadcrumb/Breadcrumb";
import Dialog from "../../molecules/Dialog/Dialog";
import Alert from "../../molecules/Alert/Alert";
import { AppUMService as AppService } from '../../../network/useAxios';
import {
  BREAD_CRUMBS,
  ROUTING_CONSTANTS,
  TEXT_CONSTANTS,
  USER_TYPE,
  USER_TYPE_CONFIG,
} from "../../../utils/appConstants";
import { useDataValidation } from "../../../utils/useDataValidation";
import { OnBoardingGridState, USER_TYPE_PROPS } from "./types";
import {
  InitialStateTypes,
  updateClientData,
  updateUserData,
} from "../../../store/slices/globalSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { STORE_CONFIG } from "../../../store/storeConstant";
import { AppUMService } from "../../../network/useAxios";
import {
  ONBOARD_GRID_COLUMN_CLIENT,
  ONBOARD_GRID_COLUMN_DEFAULT,
  ONBOARD_GRID_COLUMN_SPONSOR,
} from "./utils";
import { NETWORK_CONSTANTS } from "../../../network/networkConstants";

const OnboardingGrid = ({ typeOfUser }: USER_TYPE_PROPS) => {
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const dispatch: any = useAppDispatch();
  const { user, accessRights = [] } = appDataContext;
  const { checkIsEmpty, toLowerCase } = useDataValidation();
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const [onBoardingGridState, setOnBoardingGridState] =
    useState<OnBoardingGridState>({
      currentRow: "",
      gridLoading: false,
      openAlert: false,
      openAlertMsg: "",
      openAlertStatus: "",
      openDialog: false,
      userList: [],
    });
  const {
    currentRow,
    gridLoading,
    openAlert,
    openAlertMsg,
    openAlertStatus,
    openDialog,
    userList,
  } = onBoardingGridState;
  const {
    VALIDATION_TEXT: {
      COMMON: { ERROR, SUCCESS },
      ONBOARDING: { USER_DELETE_SUCCESS, USER_DELETE_FAILURE },
    },
  } = TEXT_CONSTANTS;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {
    name,
    path,
    type = USER_TYPE.CLIENT,
  } = getUserDetails(toLowerCase(typeOfUser));

  const breadCrumbList = [
    { title: BREAD_CRUMBS.HOME, link: ROUTING_CONSTANTS.DASHBOARD },
    { title: BREAD_CRUMBS.ONBOARDING, link: "" },
    { title: name, link: "" },
  ];

  const editID = useRef(0);

  React.useEffect(() => {
    loadUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateState = (data: any) => {
    setOnBoardingGridState((prevState)=>{
      const _updatedState:any={...prevState};
      Object.keys(data).forEach(function(key:string) {
        _updatedState[key]=data[key]
      });
      return _updatedState;
    });
  };
  function getUserDetails(key: string) {
    switch (key) {
      case toLowerCase(USER_TYPE.USER):
        return {
          name: USER_TYPE_CONFIG[USER_TYPE.USER].name,
          type: USER_TYPE.USER,
          path: ROUTING_CONSTANTS.USERS,
        };
      case toLowerCase(USER_TYPE.CLIENT):
        return {
          name: USER_TYPE_CONFIG[USER_TYPE.CLIENT].name,
          type: USER_TYPE.CLIENT,
          path: ROUTING_CONSTANTS.CLIENT,
        };
      case toLowerCase(USER_TYPE.CLIENT_USER):
        return {
          name: USER_TYPE_CONFIG[USER_TYPE.CLIENT_USER].name,
          type: USER_TYPE.CLIENT_USER,
          path: ROUTING_CONSTANTS.CLIENT_USER,
        };
      case toLowerCase(USER_TYPE.SPONSOR):
        return {
          name: USER_TYPE_CONFIG[USER_TYPE.SPONSOR].name,
          type: USER_TYPE.SPONSOR,
          path: ROUTING_CONSTANTS.SPONSORS,
        };
      default:
        return {
          name: USER_TYPE_CONFIG[USER_TYPE.USER].name,
          type: USER_TYPE.USER,
          path: ROUTING_CONSTANTS.USERS,
        };
    }
  }
  const loadUser = () => {
    let userTypeCondition = "";
    if (
      type === USER_TYPE.CLIENT_USER ||
      user?.type === USER_TYPE.CLIENT ||
      (user?.type === USER_TYPE.ADMIN &&
        !checkIsEmpty(user?.clientUserName))
    ) {
      userTypeCondition = `&clientUserName=${user?.username}`;
    }

    AppUMService.axios
      .get(`users?userType=${type}&sortBy=createdDate${userTypeCondition}`)
      .then(({ status, data }) => {
        if (status === 200) {
          updateState({
            gridLoading: false,
            userList: data.length > 0 ? data : [],
          });
        }
      })
      .catch((e) => {
        updateState({ gridLoading: false, userList: [] });
        console.log(`Error in user list - ${e}`);
      });
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    params: GridRenderCellParams
  ) => {
    editID.current = params.row.id;
    setAnchorEl(event.currentTarget);
    updateState({ currentRow: params.row });
  };

  const handleEdit = () => {
    navigate(`${path}/form/${editID.current}/`);
  };
  const handleRemove = () => {
    updateState({ openDialog: true });
    setAnchorEl(null);
  };
  const handleOpenSponsers = (e: React.MouseEvent<HTMLElement>) => {
    dispatch(
      updateUserData({
        clientUserName: currentRow?.clientUserName,
      })
    );
    navigate(`${ROUTING_CONSTANTS.SPONSORS}`);
  };

  const handleOpenProject = () => {
    dispatch(
      updateClientData({
        id: editID.current,
      })
    );
    navigate(`${ROUTING_CONSTANTS.PROJECT}${editID.current}`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseDialog = () => {
    updateState({ openDialog: false });
  };
  const handleAcceptDialog = () => {
    AppService.axios
      .delete(`${NETWORK_CONSTANTS.END_POINTS.USERS}${editID.current}`)
      .then((res) => {
        if (res.status === 200) {
          loadUser();

          updateState({
            openAlert: true,
            openDialog: false,
            openAlertMsg: SUCCESS,
            openAlertStatus: USER_DELETE_SUCCESS,
          });
        }
      })
      .catch((e) => {
        updateState({
          gridLoading: false,
          openAlert: true,
          openAlertMsg: ERROR,
          openAlertStatus: USER_DELETE_FAILURE,
        });
        console.log(`Error in remove user - ${e}`);
      });
  };

  const handleCloseAlert = () => {
    updateState({ openAlert: false, openAlertMsg: "", openAlertStatus: "" });
    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      updateState({ openAlert: false, openAlertMsg: "", openAlertStatus: "" });
    }, 5000);
  };
  const getColumns = () => {
    let column: GridColDef[] = [];
    if (type ===USER_TYPE.CLIENT) {
      column = [
        ...ONBOARD_GRID_COLUMN_CLIENT,
        {
          field: "action",
          headerName: "Action",
          width: 30,
          renderCell: (params: GridRenderCellParams): React.ReactNode => {
            return (
              <>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(event) => handleClick(event, params)}
                >
                  <MoreIcon />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {(accessRights.indexOf("Edit Client") >= 0 ||
                    user?.type === USER_TYPE.ADMIN) && (
                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  )}
                  <MenuItem onClick={handleRemove}>Remove</MenuItem>
                  <MenuItem onClick={handleOpenSponsers}>Sponsor</MenuItem>
                  {type === USER_TYPE.CLIENT &&
                    user?.type !== USER_TYPE.ADMIN && (
                      <MenuItem onClick={handleOpenProject}>Projects</MenuItem>
                    )}
                </Menu>
              </>
            );
          },
        },
      ];
    } else if (type ===USER_TYPE.SPONSOR) {
      column = [
        ...ONBOARD_GRID_COLUMN_SPONSOR,
        {
          field: "action",
          headerName: "Action",
          width: 30,
          renderCell: (params: GridRenderCellParams): React.ReactNode => {
            return (
              <>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(event) => handleClick(event, params)}
                >
                  <MoreIcon />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  <MenuItem onClick={handleRemove}>Remove</MenuItem>
                  <MenuItem onClick={handleOpenProject}>Projects</MenuItem>
                </Menu>
              </>
            );
          },
        },
      ];
    } else {
      column = [
        ...ONBOARD_GRID_COLUMN_DEFAULT,
        {
          field: "action",
          headerName: "Action",
          width: 30,
          renderCell: (params: GridRenderCellParams): React.ReactNode => {
            return (
              <>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(event) => handleClick(event, params)}
                >
                  <MoreIcon />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {(accessRights.indexOf("Edit User") >= 0 ||
                    user?.type === USER_TYPE.ADMIN) && (
                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  )}
                  <MenuItem onClick={handleRemove}>Remove</MenuItem>
                  {user?.type === USER_TYPE.CLIENT && (
                    <MenuItem onClick={handleOpenProject}>Projects</MenuItem>
                  )}
                </Menu>
              </>
            );
          },
        },
      ];
    }
    return column;
  };
  function getAllowToStatus() {
    let allowToAdd = "No";
    if (type === USER_TYPE.CLIENT || type === USER_TYPE.CLIENT_USER) {
      if (
        accessRights.indexOf("Create Client") >= 0 ||
        user?.type === USER_TYPE.ADMIN ||
        user?.type === USER_TYPE.CLIENT
      ) {
        allowToAdd = "Yes";
      }
    } else if (type === USER_TYPE.SPONSOR) {
      allowToAdd = "Yes";
    } else {
      if (
        accessRights.indexOf("Create Users") >= 0 ||
        user?.type === USER_TYPE.ADMIN
      ) {
        allowToAdd = "Yes";
      }
    }
    return allowToAdd;
  }
  function QuickSearchToolbar() {
    return (
        <Box
            sx={{
              p: 0.5,
              pb: 0,
            }}
        >
          <GridToolbarQuickFilter
              quickFilterParser={(searchInput: string) =>
                  searchInput
                      .split(',')
                      .map((value) => value.trim())
                      .filter((value) => value !== '')
              }
              quickFilterFormatter={(quickFilterValues) => quickFilterValues.join(', ')}
              debounceMs={200} // time before applying the new quick filter value
          />
        </Box>
    );
  }
  return (
    <React.Fragment>
      <Grid item xs={12} md={8} lg={9}>
        <Breadcrumb breadCrumbList={breadCrumbList} />
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box display={"flex"} flexDirection={"row"}>
            <Typography
              component="h3"
              variant="subtitle1"
              color="primary"
              gutterBottom
            >
              {name}
            </Typography>
          </Box>
          {getAllowToStatus() === "Yes" && (
            <Box
              mb={2}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Link to={`${pathname}/form`}>
                <Button id="basic-button" variant="contained">
                  Add {name}
                </Button>
              </Link>
            </Box>
          )}
          <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              rows={userList}
              columns={getColumns()}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterLogicOperator: GridLogicOperator.Or,
                  },
                },
              }}
              pageSizeOptions={[5, 10]}
              disableRowSelectionOnClick
              disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              loading={gridLoading}
              localeText={{
                noRowsLabel:
                  gridLoading === false && userList.length === 0
                    ? `No ${name} Found`
                    : "",
              }}
              slots={{ toolbar: QuickSearchToolbar }}
            />
          </div>
        </Paper>
      </Grid>
      <Dialog
        openDialog={openDialog}
        dialogMsg={"Are you sure want remove this " + name + "?"}
        dialogHeader={"Alert"}
        handleCloseDialog={handleCloseDialog}
        handleAcceptDialog={handleAcceptDialog}
      />
      <Alert
        openAlertStatus={openAlertStatus}
        openAlert={openAlert}
        openAlertMsg={openAlertMsg}
        handleCloseAlert={handleCloseAlert}
      />
    </React.Fragment>
  );
};

export default OnboardingGrid;
