
import React, { useState } from 'react';
import { Grid, Paper, MenuItem, Button, FormControl, Box, TextField } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import Breadcrumb from '../../organisms/Breadcrumb/Breadcrumb';
import Alert from '../../molecules/Alert/Alert';
import { useAppSelector } from '../../../hooks';
import { InitialStateTypes } from '../../../store/slices/globalSlice';
import { STORE_CONFIG } from '../../../store/storeConstant';
import {
  BREAD_CRUMBS,
  LOGIN_MODES,
  ROUTING_CONSTANTS,
  TEXT_CONSTANTS,
  useDataValidation
} from '../../../utils';
import { HTTP_STATUS_CODE, NETWORK_CONSTANTS } from '../../../network/networkConstants';
import { StudyAccessState } from './types';
import { AppSMService, AppUMService } from '../../../network/useAxios';

const Studysubject = () => {
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { client, study, user, project } = appDataContext;
  const { HOME, STUDY_MANAGEMENT, ACCESS } = BREAD_CRUMBS;
  const { DASHBOARD, STUDY: RSTUDY } = ROUTING_CONSTANTS;
  const { STUDY_ROLES, USERS, STUDY_ACCESS_RIGHTS, STUDY, USER_ACCESS } =
    NETWORK_CONSTANTS.END_POINTS;
  const breadCrumbList = [
    { title: HOME, link: DASHBOARD },
    { title: STUDY_MANAGEMENT, link: `${RSTUDY}${client.id}/${study.id}` },
    { title: ACCESS, link: '' }
  ];

  const {
    VALIDATION_TEXT: {
      COMMON: { ERROR, SOMETHING_WENT_WRONG, SUCCESS },
      STUDY_PAGE: { ACCESS_CREATE_SUCCESS, ACCESS_UPDATE_SUCCESS }
    }
  } = TEXT_CONSTANTS;
  const { checkIsEmpty, split } = useDataValidation();
  const [studyAccessState, setStudyAccessState] = useState<StudyAccessState>({
    userList: [],
    accessUser: '',
    roleList: [],
    studyRoleID: '',
    selectedstudyRoleName: '',
    nextStep: false,
    studyRoleName: '',
    btnloading: false,
    accessRights: [],
    optionAccess: [],
    options: [],
    accessID: '',
    siteList: [],
    siteID: [],
    mode: [],
    openAlert: false,
    openAlertMsg: '',
    openAlertStatus: ''
  });
  const {
    accessID,
    accessRights,
    accessUser,
    btnloading,
    mode,
    nextStep,
    openAlert,
    openAlertMsg,
    openAlertStatus,
    optionAccess,
    options,
    roleList,
    selectedstudyRoleName,
    siteID,
    siteList,
    studyRoleID,
    studyRoleName,
    userList
  } = studyAccessState;
  React.useEffect(() => {
    loadRole();
    loadUsers();
    loadaccessRights();
    loadSite();
  }, []);

  React.useEffect(() => {
    loadSelectedaccessRights();
  }, [accessUser]);
  const updateState = (data: any) => {
    setStudyAccessState((prevState: StudyAccessState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };
  const loadRole = () => {
    AppUMService.axios.get(STUDY_ROLES).then(({ status, data }) => {
      if (status === HTTP_STATUS_CODE.OK) {
        if (data.length > 0) {
          const _data = [...data, { id: '0', name: 'Add New Custom Role' }];
          updateState({ roleList: _data });
        }
      }
    });
  };
  const loadUsers = () => {
    AppUMService.axios
      .get(
        `${USERS}?userType=client_user&sortBy=createdDate&clientUserName=${user?.username}`
      )
      .then(({ status, data }) => {
        if (status === HTTP_STATUS_CODE.OK) {
          if (data.length > 0) {
            updateState({ userList: data });
          }
        }
      });
  };

  const loadaccessRights = () => {
    AppUMService.axios
      .get(STUDY_ACCESS_RIGHTS)
      .then(({ status, data }) => {
        if (status === HTTP_STATUS_CODE.OK) {
          if (data.length > 0) {
            updateState({ accessRights: data });
          }
        }
      })
      .catch((e) => {
        console.log(`Error in AccessRights - ${e}`);
      });
  };

  const loadSelectedaccessRights = () => {
    if (accessUser !== '') {
      AppUMService.axios
        .get(`${USERS}${accessUser}/studyRoles/${studyRoleID}/accessibleEntities`)
        .then(({ status, data }) => {
          if (status === 200) {
            const accessible: any = [];
            if (data.accessibleEntities.length > 0) {
              data.accessibleEntities.map((item: any) => {
                if (item.type === 'Site') {
                  accessible.push(item.id + '~' + item.name);
                }
              });
            }

            const accessRights = !checkIsEmpty(data.accessRights) ? data.accessRights : [];

            const accessibleModes = !checkIsEmpty(data.accessibleModes) ? data.accessibleModes : [];
            updateState({
              accessID: data.id,
              optionAccess: accessRights,
              siteID: accessible,
              mode: accessibleModes
            });
          } else {
            updateState({
              accessID: '',
              optionAccess: [],
              siteID: [],
              mode: []
            });
          }
        })
        .catch((e) => {
          updateState({
            accessID: '',
            optionAccess: [],
            siteID: [],
            mode: []
          });

          console.log(`Error in AccessRights - ${e}`);
        });
    }
  };

  const loadSite = () => {
    AppSMService.axios.get(`${STUDY}/${study.id}/config?type=SITE`).then(({ status, data }) => {
      if (status === HTTP_STATUS_CODE.OK) {
        if (data.length > 0) {
          const keysList = Object.keys(data[0].data);
          const updatedSite: any = data.map((item: any) => {
            return { id: item.id, name: item.data[keysList[0]] };
          });
          updateState({ siteList: updatedSite });
        }
      }
    });
  };

  const handleSelectFieldChange = (event: SelectChangeEvent<typeof options>) => {
    const {
      target: { name }
    } = event;
    const value: any = event.target.value;
    if (name === 'studyrole') {
      const splitValue = split('~', value);
      updateState({ studyRoleID: splitValue[0], selectedstudyRoleName: splitValue[1] });
    } else {
      updateState({ accessUser: value });
    }
  };

  const handleTextValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    updateState({ studyRoleName: value });
  };

  const saveAccess = async () => {
    const accessibleEntities: any = [];
    accessibleEntities.push(
      {
        id: client.id,
        type: 'Client',
        username: user?.username
      },
      { id: project.id, type: 'Project', name: project.name },
      { id: study.id, type: 'Study', name: study.name }
    );
    siteID.map((item: any) => {
      const split = item.split('~');
      accessibleEntities.push({ id: split[0], type: 'Site', name: split[1] });
    });

    const _modeData = mode.map((name: string) => {
      return {
        accessRights: optionAccess,
        name
      };
    });
    const styleRoleData = {
      mode: _modeData,
      name: selectedstudyRoleName,
      id: studyRoleID
    };
    let response: any = { status: HTTP_STATUS_CODE.OK };
    const requestData = {
      username: accessUser,
      accessibleEntities: accessibleEntities,
      accessibleModes: mode,
      accessRights: optionAccess,
      //dbMode: context.study.mode,
      studyRole: styleRoleData
    };
    if (!checkIsEmpty(accessID)) {
      response = await AppUMService.axios.put(`${USER_ACCESS}/${accessID}`, requestData);
    } else {
      response = await AppUMService.axios.post(`${USER_ACCESS}`, requestData);
    }
    if (HTTP_STATUS_CODE.OK === response.status) {
      updateState({
        openAlert: true,
        openAlertStatus: SUCCESS,
        openAlertMsg: !checkIsEmpty(accessID) ? ACCESS_UPDATE_SUCCESS : ACCESS_CREATE_SUCCESS
      });
    } else {
      updateState({
        openAlert: true,
        openAlertStatus: ERROR,
        openAlertMsg: SOMETHING_WENT_WRONG
      });
    }
    AutoCloseAlert();
  };

  const handleCloseAlert = () => {
    updateState({
      openAlert: false,
      openAlertStatus: '',
      openAlertMsg: ''
    });
    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      updateState({
        openAlert: false,
        openAlertStatus: '',
        openAlertMsg: ''
      });
    }, 3000);
  };

  const handleChangeMultiple = (event: any) => {
    const { options, name } = event.target;
    const _values: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        _values.push(options[i].value);
      }
    }
    if (name === 'access') {
      updateState({
        optionAccess: _values
      });
    } else if (name === 'site') {
      updateState({
        siteID: _values
      });
    } else if (name === 'mode') {
      updateState({
        mode: _values
      });
    }
  };
  const gotoNext = () => {
    if (studyRoleID === '0') {
      updateState({
        btnloading: true
      });
      AppUMService.axios
        .post(`${STUDY_ROLES}`, {
          name: studyRoleName
        })
        .then(({ data }) => {
          updateState({
            btnloading: true,
            nextStep: true,
            selectedstudyRoleName: studyRoleName,
            studyRoleID: data.id,
            studyRoleName: ''
          });

          loadRole();
        })
        .catch(() => {
          updateState({
            btnloading: false
          });
        });
    } else {
      updateState({
        nextStep: true
      });
    }
  };
  const goBackRole = () => {
    updateState({
      nextStep: false,
      accessUser: '',
      optionAccess: [],
      mode: [],
      siteID: []
    });
  };
  return (
    <React.Fragment>
      <Grid item xs={12} md={8} lg={9}>
        <Breadcrumb breadCrumbList={breadCrumbList} />
        <Paper
          sx={{
            p: 2
          }}>
          {nextStep === false && (
            <>
              <FormControl sx={{ m: 1, minWidth: 300 }}>
                <InputLabel id="demo-simple-select-helper-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  onChange={handleSelectFieldChange}
                  name="studyrole">
                  {roleList.map((item: any, index: number) => {
                    return (
                      <MenuItem value={item.id + '~' + item.name} key={index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {studyRoleID === '0' && (
                <FormControl sx={{ m: 1, minWidth: 300 }}>
                  <TextField
                    label="New Role Name"
                    sx={{
                      width: 300
                    }}
                    value={studyRoleName}
                    type="text"
                    onChange={(event) => handleTextValueChange(event)}
                  />
                </FormControl>
              )}
              <Box mb={2} mt={2} display="flex" justifyContent="flex-end" alignItems="flex-end">
                <LoadingButton
                  onClick={gotoNext}
                  loading={btnloading}
                  loadingPosition="start"
                  variant="contained">
                  Next
                </LoadingButton>
              </Box>
            </>
          )}
          {studyRoleID !== '' && nextStep === true && (
            <>
              <FormControl sx={{ m: 1, minWidth: 300 }}>
                <TextField
                  disabled
                  id="outlined-disabled"
                  label="Role Name"
                  value={selectedstudyRoleName}
                />
              </FormControl>

              <FormControl sx={{ m: 1, minWidth: 300 }}>
                <InputLabel id="demo-simple-select-helper-label">User *</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  onChange={handleSelectFieldChange}
                  name="accessuser">
                  {userList.map((item: any, index: number) => {
                    return (
                      <MenuItem value={item.username} key={index}>
                        {item.username}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Box></Box>
              {accessUser !== '' && (
                <>
                  <FormControl sx={{ m: 1, minWidth: 300 }}>
                    <InputLabel
                      id="demo-simple-select-helper-label"
                      className=" Mui-focused"
                      focused={true}>
                      Mode
                    </InputLabel>
                    <Select
                      multiple
                      native
                      value={mode}
                      onChange={handleChangeMultiple}
                      label="Native"
                      name="mode"
                      inputProps={{
                        id: 'select-multiple-native',
                        style: { height: '200px' }
                      }}
                      style={{ height: '240px' }}>
                      {LOGIN_MODES.map((data: any) => {
                        if (data.value !== LOGIN_MODES[3].value)
                          return (
                            <option key={data.value} value={data.value}>
                              {data.name}
                            </option>
                          );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 300 }}>
                    <InputLabel
                      id="demo-simple-select-helper-label"
                      className=" Mui-focused"
                      focused={true}>
                      Access
                    </InputLabel>
                    <Select
                      multiple
                      native
                      value={optionAccess}
                      // @ts-ignore Typings are not considering `native`
                      onChange={handleChangeMultiple}
                      label="Native"
                      name="access"
                      inputProps={{
                        id: 'select-multiple-native',
                        style: { height: '200px' }
                      }}
                      style={{ height: '240px' }}>
                      {accessRights.map((item: any, index: number) => {
                        return (
                          <option key={index} value={item.code}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 300 }}>
                    <InputLabel
                      id="demo-simple-select-helper-label"
                      className=" Mui-focused"
                      focused={true}>
                      Site
                    </InputLabel>
                    <Select
                      multiple
                      native
                      value={siteID}
                      // @ts-ignore Typings are not considering `native`
                      onChange={handleChangeMultiple}
                      label="Native"
                      name="site"
                      inputProps={{
                        id: 'select-multiple-native',
                        style: { height: '200px' }
                      }}
                      style={{ height: '240px' }}>
                      {siteList.map((item: any, index: number) => {
                        return (
                          <option key={index} value={item.id + '~' + item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
              <Box mb={2} mt={2} display="flex" justifyContent="flex-end" alignItems="flex-end">
                <Button variant="contained" onClick={goBackRole} style={{ marginRight: 20 }}>
                  Back
                </Button>
                <Button variant="contained" onClick={saveAccess}>
                  Save
                </Button>
              </Box>
            </>
          )}
        </Paper>
      </Grid>
      <Alert
        openAlertStatus={openAlertStatus}
        openAlert={openAlert}
        openAlertMsg={openAlertMsg}
        handleCloseAlert={handleCloseAlert}
      />
    </React.Fragment>
  );
};

export default Studysubject;
