import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

interface ICusomTable {
  filterlist: any;
  buttonlabel: any;
  setInputValue: (value: string, id: string) => void;
  applyFilter: () => void;
}
function Filters(props: ICusomTable) {
  const filterlist = props.filterlist;
  return (
    <React.Fragment>
      <Typography
        component="h3"
        variant="subtitle1"
        color="primary"
        gutterBottom
      >
        Quick Filter
      </Typography>

      <Grid container spacing={2}>
        {filterlist.map((listitem: any) => {
          return (
            <Grid item xs={12} sm={2} key={listitem.id}>
              <Autocomplete
                value={listitem.value}
                id={listitem.id}
                autoHighlight
                options={listitem.options}
                onInputChange={(event, newInputValue: string) => {
                  props.setInputValue(newInputValue, listitem.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={listitem.label}
                    variant="standard"
                    sx={{
                      "& .MuiFormLabel-root": {
                        fontSize: "1rem",
                      },
                    }}
                  />
                )}
              />
            </Grid>
          );
        })}
        <Grid item xs={12} sm={2} mt={2} ml={4}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={props.applyFilter}
          >
            {props.buttonlabel}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Filters;
