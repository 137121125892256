export const FilterSetup = JSON.stringify([
  {
    value: '',
    options: [],
    type: 'auctocomplte',
    id: 'client',
    label: 'Client Name',
    visibility: true
  },
  {
    value: '',
    options: [],
    type: 'auctocomplte',
    id: 'sponsor',
    label: 'Sponsor',
    visibility: true
  },
  {
    value: '',
    options: [],
    type: 'auctocomplte',
    id: 'project',
    label: 'Project Name',
    visibility: true
  },
  {
    value: '',
    options: [],
    type: 'auctocomplte',
    id: 'study',
    label: 'Study Name',
    visibility: true
  },
  {
    value: '',
    options: [],
    type: 'auctocomplte',
    id: 'mode',
    label: 'Mode',
    visibility: true
  }
]);
